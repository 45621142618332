import * as React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import HeaderScriptsComponent from "../components/headerScripts";

export const query = graphql`
    query ($uid: String) {
        entry: craftCareersDefaultEntry(uid: {eq: $uid}) {
            seomatic {
                ... on Craft_SeomaticType {
                    metaTitleContainer
                    metaTagContainer
                    metaLinkContainer
                    metaScriptContainer
                    metaJsonLdContainer
                    metaSiteVarsContainer
                }
            }
            title
            careerDescription
            careerRequirements
        }
    }
`

const CareerTemplate = ({data}) => {
    return (
        <Layout seomatic={data.entry.seomatic}>
            <section className="page-hero-section section-regular bg-white page-hero-section-bg">
                <div className="container">
                    <div className="row pt-30 pb-20">
                        <div className="col-12">
                            <div className="hero-section-content text-center">
                                <h1 className="f-midium text-uppercase">Open Position - {data.entry.title}</h1>
                                <p className="mb-0 text-uppercase h1 border-0">Cordiance is Looking for You!</p>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-20 pb-30">
                        <div className="col-12 col-lg-10 text-center m-auto button-anchor responsive-iframe">
                            {parse(data.entry.careerDescription)}
                        </div>
                    </div>
                </div>
            </section>
            {data.entry.careerRequirements &&
                <section className="position-relative parallax-bg text-left color-white section-regular" style={{backgroundImage: `url('https://assets.cordiance.com/craft/hexagon-quantum-indirect-tax-technology-dark-01-scaled.jpg')`}}>
                    <div className="container">
                        <div className="row ptb-30">
                            <h3 className="text-white h3 text-center">Applicant Requirements</h3>
                        </div>
                        <div className="row ptb-30">
                            <div className="col-12 text-white two-column-text">{parse(data.entry.careerRequirements)}</div>
                        </div>
                    </div>
                </section>
            }
            <section className="page-hero-section section-regular bg-white page-hero-section-bg centered-content" style={{backgroundPosition: 'center top'}}>
                <div className="container">
                    <div className="row ptb-30">
                        <div className="col-12 text-center m-auto responsive-iframe">
                            <p>Please submit resumes (and cover letters if you feel like standing out) to <a href="mailto:info@cordiance.com">info@cordiance.com</a>.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="position-relative">
                <div className="parallax-bg-height-sm" style={{backgroundImage: `url('https://assets.cordiance.com/craft/san-francisco-skyline-at-sunrise-scaled.jpg')`}}></div>
            </section>
        </Layout>
    )
}

export default CareerTemplate

export const Head = ({data}) => (
    <HeaderScriptsComponent data={data} />
)